import { OrgTask } from '../components/model/model'

const goachyApiUrl = import.meta.env.VITE_CHALLENGE_API_URL

export type GetOrganizationPublicTasksRequest = {
  organizationId: string
}

export type GetOrganizationPublicTasksResponse = {
  orgTasks: OrgTask[]
  status: 'SUCCESS' | 'NOT_FOUND' | 'FAILURE'
  message?: string
}

export class OrganizationTaskService {
  public async getPublicTasks({
    organizationId,
  }: GetOrganizationPublicTasksRequest): Promise<GetOrganizationPublicTasksResponse> {
    try {
      if (!goachyApiUrl) {
        throw new Error('Missing goachyApiUrl')
      }

      const response = await fetch(
        `${goachyApiUrl}organizations/${organizationId}/publictasks`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      if (response.status === 404) {
        return {
          orgTasks: [],
          status: 'NOT_FOUND',
        }
      }

      const orgTasks = (await response.json()) as OrgTask[]

      return {
        orgTasks: orgTasks,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get all public tasks owned by organization, error:', error)
      return {
        orgTasks: [],
        status: 'FAILURE',
        message: 'Failed to get all public tasks owned by organization',
      }
    }
  }
}

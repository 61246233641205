import { getCurrentUser } from 'aws-amplify/auth'
import { Suspense, lazy, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import '../css/CustomTableDefault.css'
import '../css/index.css'
import { GoachyRole } from './Roles'
import { updateTheme } from './Themes'
import FallbackPage from './components/FallbackPage'
import ProgressSpinner from './components/ProgressSpinner'
import ScrollToTop from './components/ScrollToTop'
import SharedLayout from './components/SharedLayout'
import ErrorBoundary from './components/auth/ErrorBoundary'
import LoginComponent from './components/auth/LoginComponent'
import SignOutComponent from './components/auth/SignOutComponent'
import { TaskDataType } from './components/model/model'
import { GoalProvider } from './context/GoalContext'
import { PreviousLocationProvider } from './context/PreviousLocationContextType'
import { TaskProvider } from './context/TaskContext'
import { useUserCredentials } from './context/UserCredentialsContext'
import { UserTaskProvider } from './context/UserTaskContext'
import AboutPage from './pages/AboutPage'
import HomePage from './pages/HomePage'
import MyAccountPage from './pages/MyAccountPage'
import { AddGoalPage } from './pages/goals/AddGoalPage'
import { EditGoalPage } from './pages/goals/EditGoalPage'
import MyGoalsPage from './pages/goals/MyGoalsPage'

import ProtectedRoute from './components/auth/ProtectedRoute'
import { UserTaskListProvider } from './context/UserTaskListContext'
import { useCheckAndUpdateUserCredentials } from './hooks/useCheckUser'
import ReviewAndSaveTaskPage from './pages/admin/tasks/ReviewAndSaveTaskPage'
import WrittenAnswerStartPage from './pages/tasks/question-and-answer/WrittenAnswerMainPage'
import MyUserTasksPage from './pages/user-tasks/MyUserTasksPage'
import { useOrganizationService, useUserService } from './service/ServicesHooks'
import TermsAndConditions from './components/TermsAndConditions'
import { SelectedCategoryProvider } from './context/SelectedCategoryContext'
import AddQuestionAndMultipleAnswersPage from './pages/admin/tasks/AddQuestionAndMultipleAnswersPage'
import MultipleAnswersStartPage from './pages/tasks/multiple-answers/MultipleAnswersMainPage'

import PublicOrganizationTasksPage from './pages/public-tasks/PublicOrganizationTasksPage'
import SharedOrPublicTaskPage from './pages/task-public-shared/SharedOrPublicTaskPage'

export interface UserCredentials {
  isSignedIn: boolean
  userInfo?: {
    userId: string
    email: string
    organizationId: string
    organizationName: string
    roles: GoachyRole[]
    theme?: string
  }
}
const queryClient = new QueryClient()

//Lazy load some pages and components

const ListTasksPageAdmin = lazy(
  () => import('./pages/admin/tasks/ListOrgTasksPage'),
)
const OrganizationEditPage = lazy(
  () => import('./pages/admin/organizations/OrganizationEditPage'),
)
const SuperAdminHomePage = lazy(
  () => import('./pages/admin/SuperAdminHomePage'),
)
const ManualTaskUserDelegationPage = lazy(
  () => import('./pages/admin/tasks/ManualTaskUserDelegationPage'),
)
const ListUserTasksPageAdmin = lazy(
  () => import('./pages/admin/tasks/ListUserTasksPageAdmin'),
)
const UsersOrganizationListPage = lazy(
  () => import('./pages/admin/users/UsersOrganizationListPage'),
)
const UsersAllListPage = lazy(
  () => import('./pages/admin/users/UsersAllListPage'),
)
const OrganizationsPage = lazy(
  () => import('./pages/admin/organizations/OrganizationsPage'),
)
const AddQuestionAndAnswerPage = lazy(
  () => import('./pages/admin/tasks/AddQuestionAndAnswerPage'),
)
const AddTaskPage = lazy(() => import('./pages/admin/tasks/AddTaskPage'))
const EditTaskPage = lazy(() => import('./pages/admin/tasks/EditTaskPage'))
const UserEditPage = lazy(() => import('./pages/admin/users/UserEditPage'))

const AddTaskToGoalPage = lazy(() => import('./pages/goals/AddTaskToGoalPage'))
const ListGoalsForOrganization = lazy(
  () => import('./pages/goals/ListGoalsForOrganization'),
)

const MyGoalPage = lazy(() => import('./pages/goals/MyGoalPage'))
const FlashCardTaskPage = lazy(
  () => import('./pages/tasks/question-and-answer/FlashCardTaskPage'),
)
const ShowAllQAndAPage = lazy(
  () => import('./pages/tasks/question-and-answer/ShowAllQAndAPage'),
)
const MyUserTaskPage = lazy(() => import('./pages/user-tasks/MyUserTaskPage'))

//TODO: Bug, when lazy loading these components, the hamburger menu does not work (it is not closing)
// const LoginComponent = lazy(() => import('./components/auth/LoginComponent'))
// const SignOutComponent = lazy(
//   () => import('./components/auth/SignOutComponent'),
// )
// const AboutPage = lazy(() => import('./pages/AboutPage'))

function App() {
  const { userCredentialsInContext, updateUserCredentialsContext } =
    useUserCredentials()

  const userService = useUserService()
  const organizationService = useOrganizationService()

  const [signInInProgress, setSignInInProgress] = useState(false)

  const { checkUser, loading } = useCheckAndUpdateUserCredentials()

  useEffect(() => {
    checkUser()
    const loadingDiv = document.getElementById('loading')
    if (loadingDiv) {
      loadingDiv.remove()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoginLogout = async (isSignedIn: boolean) => {
    setSignInInProgress(true)
    if (isSignedIn) {
      const { signInDetails } = await getCurrentUser()

      if (!signInDetails || !signInDetails.loginId) {
        updateUserCredentialsContext(null)

        console.error('signInDetails.loginId is undefined')
        return
      }

      const response = await userService.getUserByEmail(signInDetails.loginId!)
      const currentUser = response.users[0]

      const getOrganizationResponse = await organizationService.getOrganization(
        currentUser.organizationId!,
      )
      const organization = getOrganizationResponse.organizations[0]

      updateTheme(organization.name)

      const localStorageUserInfo = {
        userId: currentUser.id!,
        organizationId: currentUser.organizationId,
        email: currentUser.email,
        organizationName: organization.name,
        roles: currentUser.roles,
      }

      updateUserCredentialsContext({
        isSignedIn: true,
        userInfo: localStorageUserInfo,
      })

      localStorage.setItem('goachyUser', JSON.stringify(localStorageUserInfo))
    } else {
      updateUserCredentialsContext(null)
      localStorage.removeItem('goachyUser')
    }
    setSignInInProgress(false)
  }

  if (signInInProgress || loading) {
    return <ProgressSpinner />
  }

  const environment = import.meta.env.VITE_ENVIRONMENT
  console.log('environment', environment)
  console.log('isSignedIn', userCredentialsInContext?.isSignedIn)

  const questionAndAnswerTaskPath = `/admin/tasks/questions/${TaskDataType.QuestionAndAnswerDataType}`
  const multipleChoiceTaskPath = `/admin/tasks/questions/${TaskDataType.MultipleChoiceDataType}`

  const oneTimeTaskEditUserDelegationPage = `/admin/tasks/:taskId/add/delegate/${TaskDataType.OneTimeTaskDataType}`
  const recurringTaskEditUserDelegationPage = `/admin/tasks/:taskId/add/delegate/${TaskDataType.RecurringTaskDataType}`

  const oneTimeTaskVerifyOnlyPage = `/admin/tasks/verify/${TaskDataType.OneTimeTaskDataType}`
  const recurringVerifyOnlyPage = `/admin/tasks/verify/${TaskDataType.RecurringTaskDataType}`

  // const openUserTaskFromLink = `http://localhost:5173/usertasks/task_2f65b690-a3da-427f-9877-90d0e7cdd7a6`

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <TaskProvider>
          <UserTaskListProvider>
            <UserTaskProvider>
              <GoalProvider>
                <SelectedCategoryProvider>
                  <BrowserRouter>
                    <ErrorBoundary>
                      <PreviousLocationProvider>
                        <Suspense fallback={<FallbackPage />}>
                          <ScrollToTop />
                          <Routes>
                            <Route path="/" element={<SharedLayout />}>
                              <Route index element={<HomePage />} />

                              <Route
                                path="usertasks/list"
                                element={
                                  <ProtectedRoute>
                                    <MyUserTasksPage />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="public/tasks"
                                element={<PublicOrganizationTasksPage />}
                              />

                              <Route path="/about" element={<AboutPage />} />

                              <Route
                                path="/public/usertasks/:taskId"
                                element={<SharedOrPublicTaskPage sharedOrPublicTask='PUBLIC'/>}
                              />

                              <Route
                                path="/shared/usertasks/:taskId"
                                element={<SharedOrPublicTaskPage sharedOrPublicTask='SHARED'/>}
                              />

                              <Route
                                path="usertasks/:taskId"
                                element={
                                  // <ProtectedRoute>
                                  <MyUserTaskPage />
                                  // </ProtectedRoute>
                                }
                              />

                              <Route
                                path="usertasks/flashcard/:taskId"
                                element={<FlashCardTaskPage />}
                              />

                              <Route
                                path="usertasks/q-and-q-show-all/:taskId"
                                element={<ShowAllQAndAPage />}
                              />

                              <Route
                                path="usertasks/multiple-answers/:taskId"
                                element={<MultipleAnswersStartPage />}
                              />

                              <Route
                                path="usertasks/written-answer/:taskId"
                                element={<WrittenAnswerStartPage />}
                              />

                              {/* GOALS */}
                              <Route
                                path="goals/add"
                                element={<AddGoalPage />}
                              />
                              {/* Goal is injected by navigation state */}
                              <Route
                                path="goals/edit"
                                element={
                                  <ProtectedRoute>
                                    <EditGoalPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="goals/open"
                                element={<MyGoalPage />}
                              />
                              <Route
                                path="goals/my"
                                element={
                                  <ProtectedRoute>
                                    <MyGoalsPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="goals/list"
                                element={
                                  <ProtectedRoute>
                                    <ListGoalsForOrganization />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="goals/addtasks"
                                element={
                                  <ProtectedRoute>
                                    <AddTaskToGoalPage />
                                  </ProtectedRoute>
                                }
                              />

                              {/* ADMIN ROUTES */}
                              <Route
                                path="superadmin"
                                element={
                                  <ProtectedRoute>
                                    <SuperAdminHomePage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="admin/organizations"
                                element={
                                  <ProtectedRoute>
                                    <OrganizationsPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="admin/organizations/:organizationId/edit"
                                element={
                                  <ProtectedRoute>
                                    <OrganizationEditPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="/admin/users/:userId/edit"
                                element={
                                  <ProtectedRoute>
                                    <UserEditPage />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="/admin/organizations/:organizationId/users"
                                element={
                                  <ProtectedRoute>
                                    <UsersOrganizationListPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="admin/users"
                                element={
                                  <ProtectedRoute>
                                    <UsersAllListPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="admin/tasks/add"
                                element={
                                  <ProtectedRoute>
                                    <AddTaskPage />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="admin/tasks/list"
                                element={
                                  <ProtectedRoute>
                                    <ListTasksPageAdmin />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="admin/usertasks/:taskId/list"
                                element={
                                  <ProtectedRoute>
                                    <ListUserTasksPageAdmin />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path={questionAndAnswerTaskPath}
                                element={
                                  <ProtectedRoute>
                                    <AddQuestionAndAnswerPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path={multipleChoiceTaskPath}
                                element={
                                  <ProtectedRoute>
                                    <AddQuestionAndMultipleAnswersPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path={oneTimeTaskEditUserDelegationPage}
                                element={
                                  <ProtectedRoute>
                                    <ManualTaskUserDelegationPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path={recurringTaskEditUserDelegationPage}
                                element={
                                  <ProtectedRoute>
                                    <ManualTaskUserDelegationPage />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path={oneTimeTaskVerifyOnlyPage}
                                element={
                                  <ProtectedRoute>
                                    <ReviewAndSaveTaskPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path={recurringVerifyOnlyPage}
                                element={
                                  <ProtectedRoute>
                                    <ReviewAndSaveTaskPage />
                                  </ProtectedRoute>
                                }
                              />

                              <Route
                                path="admin/tasks/:taskId/edit"
                                element={
                                  <ProtectedRoute>
                                    <EditTaskPage />
                                  </ProtectedRoute>
                                }
                              />

                              {/* LOGIN ROUTES */}
                              <Route
                                path="login/user/:rerenderKey"
                                element={
                                  <LoginComponent
                                    setIsSignedInCallback={handleLoginLogout}
                                  />
                                }
                              />
                              <Route
                                path="login/user/:loginEmail/:rerenderKey"
                                element={
                                  <LoginComponent
                                    setIsSignedInCallback={handleLoginLogout}
                                  />
                                }
                              />
                              <Route
                                path="logout"
                                element={
                                  <SignOutComponent
                                    setIsSignedInCallback={handleLoginLogout}
                                  />
                                }
                              />
                              <Route
                                path="myaccount/:userId"
                                element={
                                  <ProtectedRoute>
                                    <MyAccountPage />
                                  </ProtectedRoute>
                                }
                              />
                              <Route
                                path="terms"
                                element={<TermsAndConditions />}
                              />
                            </Route>
                          </Routes>
                        </Suspense>
                      </PreviousLocationProvider>
                    </ErrorBoundary>
                  </BrowserRouter>
                </SelectedCategoryProvider>
              </GoalProvider>
            </UserTaskProvider>
          </UserTaskListProvider>
        </TaskProvider>
      </QueryClientProvider>
    </>
  )
}

export default App

import { useMemo, useState } from 'react'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import { UserTaskStatus } from './model/model'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useUserTasksContext } from '../context/UserTaskListContext'
import { groupTasksByStatus } from '../utils/sortingFunctions'

export interface UserTaskStateButtonsProps {
  onChangeStateCb: (state: string) => void
}
const UserTaskStateButtons = ({
  onChangeStateCb,
}: UserTaskStateButtonsProps) => {
  const { t } = useTranslation('CommonTranslations')
  const [filter, setFilter] = useState('All')
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const { userTasksInContext } = useUserTasksContext()

  const groupedUserTasks = useMemo(
    () => groupTasksByStatus(userTasksInContext),
    [userTasksInContext],
  )

  const numOfAllTasks = userTasksInContext!.length

  const filters = [
    { name: t('all'), value: 'All', color: '#6c757d' },
    { name: t('new'), value: UserTaskStatus.New, color: '#4c49ea' },
    { name: t('ongoing'), value: UserTaskStatus.Ongoing, color: '#f9b234' },
    { name: t('done'), value: UserTaskStatus.Done, color: '#3ecd5e' },
    // { name: 'Sena', value: 'Late', color: '#f8d7da' },
  ]

  const onChangeState = (state: string) => {
    setFilter(state)
    onChangeStateCb(state)
  }

  const getNumOfTasksFor = (taskStatus: string) => {
    switch (taskStatus) {
      case 'All':
        return numOfAllTasks
      case UserTaskStatus.New:
        return groupedUserTasks[UserTaskStatus.New]
          ? groupedUserTasks[UserTaskStatus.New].length
          : 0
      case UserTaskStatus.Ongoing:
        return groupedUserTasks[UserTaskStatus.Ongoing]
          ? groupedUserTasks[UserTaskStatus.Ongoing].length
          : 0
      case UserTaskStatus.Done:
        return groupedUserTasks[UserTaskStatus.Done]
          ? groupedUserTasks[UserTaskStatus.Done].length
          : 0
      default:
        return '0'
    }
  }

  return (
    <ButtonGroup className={'full-width-button-group d-flex'}>
      {filters.map((f, idx) => (
        <ToggleButton
          key={idx}
          id={`filter-${idx}`}
          type="radio"
          name="filter"
          value={f.value}
          checked={filter === f.value}
          onChange={(e) => onChangeState(e.currentTarget.value)}
          style={{
            backgroundColor:
              filter === f.value ? f.color : 'var(--status-buttons-bg)',
            borderColor: f.color,
            color:
              filter === f.value ? '#000' : 'var(--status-buttons-text-color)',
            borderWidth: '4px',
            borderRadius: '10px',
          }}
        >
          {f.name}
          <span
            style={{
              display: 'inline-block',
              marginTop: '2px',
              marginLeft: isMobile ? '0px' : '15px',
              padding: '5px',
              minWidth: '30px',
              height: '33px',
              borderRadius: '10px',
              backgroundColor: f.value ? f.color : 'var(--status-buttons-bg)',
              color: '#fff',
              textAlign: 'center',
              lineHeight: '20px',
              border: '2px solid white',
            }}
          >
            {getNumOfTasksFor(f.value)}
          </span>
        </ToggleButton>
      ))}
    </ButtonGroup>
  )
}

export default UserTaskStateButtons

import { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  error?: Error
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can log the error to an error reporting service
    console.error('Error caught in Error Boundary: ', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div
            style={{
              backgroundImage: "url('/questionmark.png')",
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',

              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src="/latest/logo-transparent-200x39.png" alt="error" />
            <h1 style={{ color: 'grey' }}>Something went wrong</h1>
            <p style={{ color: 'grey' }}>{this.state.error?.toString()}</p>
            <a href="https://goachy.com">Please try to reload the page</a>
          </div>
        </>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary

import { AuthService } from './AuthService'

const goachyApiUrl = import.meta.env.VITE_CHALLENGE_API_URL

export interface TextToSpeechRequest {
  text: string
  voiceId?: string
  sampleRate?: string
}

export interface TextToSpeechResponse {
  status: 'SUCCESS' | 'FAILURE'
  audioUrl?: string
}

export class TextToSpeechService {
  private authService: AuthService

  constructor(authService: AuthService) {
    this.authService = authService
  }

  public async textToSpeech(
    request: TextToSpeechRequest,
  ): Promise<TextToSpeechResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(`${goachyApiUrl}polly`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
          Origin: window.location.origin,
        },
        body: JSON.stringify(request),
      })

      const audioBase64 = await response.text()

      // Convert base64 to an audio URL
      const audioBlob = this.base64ToBlob(audioBase64, 'audio/mpeg')
      const audioUrl = URL.createObjectURL(audioBlob)

      return {
        status: 'SUCCESS',
        audioUrl: audioUrl,
      }
    } catch (error) {
      console.error('Failed to fetch audio', error)
      return {
        status: 'FAILURE',
      }
    }
  }

  public base64ToBlob(base64: string, contentType: string) {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }
}

import { SyntheticEvent, useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AuthService } from '../../service/AuthService'
import { isValidEmail } from '../../utils/isValidEmail'
import ConfirmSignupComponent from './SignUpConfirmation'

interface SignUpProps {
  authService: AuthService
  showConfirmModal: boolean
}

const SignUpComponent = (props: SignUpProps) => {
  const { t } = useTranslation('SignUpComponent')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showConfirmSignUp, setShowConfirmSignUp] = useState(
    props.showConfirmModal,
  )
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleSignUp = async (event: SyntheticEvent) => {
    event.preventDefault()
    setShowErrorMessage(false)
    if (password !== confirmPassword) {
      setErrorMessage(t('passwordMismatch'))
      setShowErrorMessage(true)
      return
    } else if (isValidEmail(email) === false) {
      setErrorMessage(t('invalidEmail'))
      setShowErrorMessage(true)
      return
    }
    try {
      const signUpOutput = await props.authService.signUpRequest(
        email,
        password,
      )
      if (signUpOutput.nextStep?.signUpStep === 'CONFIRM_SIGN_UP') {
        setShowConfirmSignUp(true)
      }
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'UsernameExistsException':
            console.log('User already exists')
            setShowErrorMessage(true)
            setErrorMessage(t('userAlreadyExist'))
            break
          case 'InvalidPasswordException':
            setShowErrorMessage(true)
            setErrorMessage(t('invalidPassword'))
            break
          default:
            setShowErrorMessage(true)
            setErrorMessage(t('defaultError'))
            break
        }
      }
    }
  }

  return (
    <div>
      {!showConfirmSignUp && (
        <>
          <Container className="mt-2 mb-4">
            <Row className="justify-content-md-center">
              <div className="goachy-header-title  text-center mt-2">
                {t('createAccount')}
              </div>
              <Col md={12}>
                <Form onSubmit={handleSignUp} className="rounded">
                  {errorMessage && (
                    <Alert variant="danger" style={{ fontSize: '1.1rem' }}>
                      {errorMessage}
                    </Alert>
                  )}
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label htmlFor="formBasicEmail">
                      {t('email')}
                    </Form.Label>
                    <Form.Control
                      id="signUpFormBasicEmail"
                      type="email"
                      name="email"
                      placeholder={t('email')}
                      value={email}
                      autoFocus
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      required
                    />
                  </Form.Group>

                  <Form.Group className={'my-4'} controlId="formSignupPassword">
                    <Form.Label htmlFor="formSignupPassword">
                      {t('password')}
                    </Form.Label>
                    <Form.Control
                      id="formSignupPassword"
                      type={showPassword ? 'text' : 'password'} // Use showPassword state to toggle between text and password type
                      name="password"
                      placeholder={t('password')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="new-password"
                      required
                    />
                    <Form.Check
                      type="checkbox"
                      label={t('showPassword')}
                      onChange={() => setShowPassword(!showPassword)}
                      className={'mt-2'}
                    />
                  </Form.Group>

                  <Form.Group
                    className={'my-4'}
                    controlId="formConfirmSignupPassword"
                  >
                    <Form.Label htmlFor="formConfirmSignupPassword">
                      {t('confirmPassword')}
                    </Form.Label>
                    <Form.Control
                      id="formConfirmSignupPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      placeholder={t('confirmPassword')}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      autoComplete="new-password"
                      required
                    />
                    <Form.Check
                      type="checkbox"
                      label={t('showPassword')}
                      onChange={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className={'mt-2'}
                    />
                  </Form.Group>

                  <Button
                    type="submit"
                    variant="primary"
                    className="mt-3 w-100"
                  >
                    {t('createAccount')}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {showConfirmSignUp && (
        <ConfirmSignupComponent email={email} authService={props.authService} />
      )}

      {showErrorMessage && <div>{errorMessage}</div>}
    </div>
  )
}
export default SignUpComponent

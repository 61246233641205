import {
  Task,
  TaskData as TaskData,
  TaskStatus,
  UserTask,
} from '../components/model/model'
import { AuthService } from './AuthService'

const goachyApiUrl = import.meta.env.VITE_CHALLENGE_API_URL

export type GetTasksRequest = {
  taskId?: string
}
export type GetTasksResponse = {
  tasks: Task[]
  status: 'SUCCESS' | 'NOT_FOUND' | 'FAILURE'
  message?: string
}

export type GetUserTasksByTaskIdRequest = {
  taskId: string
}

export type GetUserTasksByTaskIdResponse = {
  userTasks: UserTask[]
  status: 'SUCCESS' | 'NOT_FOUND' | 'FAILURE'
  message?: string
}

export type UpdateTaskRequest = {
  id: string
  name?: string
  category?: string
  description?: string
  htmlDescription?: string
  data?: TaskData[]
  endDate?: string
  status?: TaskStatus
  organizationId: string
  userId: string
}

export type UpdateTaskResponse = {
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export type DeleteTaskResponse = {
  id: string
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export type PostTaskRequest = {
  task: Task
  email: string
  username?: string
}
export type PostTaskResponse = {
  taskId: string
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

type RequestParams = {
  jwtToken: string
  requestUrl: string
}

export class TaskService {
  private authService: AuthService

  constructor(authService: AuthService) {
    this.authService = authService
  }

  async getTasks(request: GetTasksRequest): Promise<GetTasksResponse> {
    try {
      if (!request.taskId) {
        return {
          tasks: [],
          status: 'FAILURE',
          message: 'Missing request parameters',
        }
      }

      const { jwtToken, requestUrl } = await this.createUnauthorizedRequest(request)

      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      if (response.status === 404) {
        console.log('No task found for', request)
        return {
          status: 'NOT_FOUND',
          tasks: [],
          message: `No task found for ${request}`,
        }
      }

      if (request.taskId) {
        const task = (await response.json()) as Task

        if (!task) {
          return {
            status: 'NOT_FOUND',
            tasks: [],
            message: `No task found with id ${request.taskId}`,
          }
        }

        return {
          status: 'SUCCESS',
          tasks: [task],
        }
      }
      const tasks = (await response.json()) as Task[]

      return {
        status: 'SUCCESS',
        tasks: tasks,
      }
    } catch (error) {
      console.log('Failed to get tasks, error:', error)
      return {
        tasks: [],
        status: 'FAILURE',
        message: 'Failed to get tasks',
      }
    }
  }

  async getUserTasksByTaskId({
    taskId,
  }: GetUserTasksByTaskIdRequest): Promise<GetUserTasksByTaskIdResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(`${goachyApiUrl}tasks/${taskId}/usertasks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      if (response.status === 404) {
        return {
          userTasks: [],
          status: 'NOT_FOUND',
        }
      }

      const userTasks = (await response.json()) as UserTask[]

      return {
        userTasks: userTasks,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get user tasks, error:', error)
      return {
        userTasks: [],
        status: 'FAILURE',
        message: 'Failed to get user tasks',
      }
    }
  }

  async postTask(postTaskRequest: PostTaskRequest): Promise<PostTaskResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(`${goachyApiUrl}tasks`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
        body: JSON.stringify(postTaskRequest),
      })
      const taskResponse = (await response.json()) as Task

      return {
        taskId: taskResponse.id,
        status: 'SUCCESS',
        message: `Task added, id: ${taskResponse.id}`,
      }
    } catch (error) {
      console.error('Failed to add task, error:', error)
      return {
        taskId: '',
        status: 'FAILURE',
        message: `Failed to add task, error: ${error}`,
      }
    }
  }

  public async updateTask(
    request: UpdateTaskRequest,
  ): Promise<UpdateTaskResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      await fetch(`${goachyApiUrl}tasks/${request.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
        body: JSON.stringify(request),
      })
      return {
        status: 'SUCCESS',
        message: `Task updated, id: ${request.id}`,
      }
    } catch (error) {
      console.error('Failed to update task, error:', error)
      return {
        status: 'FAILURE',
        message: `Failed to update task, error: ${error}`,
      }
    }
  }

  /**
   * The task itself is not deleted, but the user's relation to the task is deleted.
   * @param taskId
   * @returns
   */
  public async deleteTask(taskId: string): Promise<DeleteTaskResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      await fetch(`${goachyApiUrl}tasks/${taskId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      return {
        id: taskId,
        status: 'SUCCESS',
        message: `Task deleted, id: ${taskId}`,
      }
    } catch (error) {
      console.error('Failed to delete task, error:', error)
      return {
        id: taskId,
        status: 'FAILURE',
        message: `Failed to delete task, error: ${error}`,
      }
    }
  }

  async createUnauthorizedRequest(request: GetTasksRequest): Promise<RequestParams> {
    if (!goachyApiUrl) {
      throw new Error('Missing goachyApiUrl')
    }

    const requestUrl = this.createTasksRequestApiUrl(request)

    return {
      jwtToken: '',
      requestUrl: requestUrl,
    }
  }

  async createRequest(request: GetTasksRequest): Promise<RequestParams> {
    const jwtToken = await this.authService.getJwtToken()

    if (!jwtToken) {
      throw new Error('Missing jwtToken')
    }

    if (!goachyApiUrl) {
      throw new Error('Missing goachyApiUrl')
    }

    const requestUrl = this.createTasksRequestApiUrl(request)

    return {
      jwtToken: jwtToken,
      requestUrl: requestUrl,
    }
  }

  createTasksRequestApiUrl({ taskId: taskId }: GetTasksRequest): string {
    let apiUrl = `${goachyApiUrl}tasks`

    if (taskId) {
      apiUrl += `/${taskId}`
    }

    console.log(apiUrl)
    return apiUrl
  }
}

import { TaskEvent } from '../components/model/model'
import { convertToDate } from './converters'

export interface TestEvent {
  name: string // Example property
  created: Date
}

export const findPreviousMonday = (date: Date): Date => {
  date.setDate(date.getDate() - ((date.getDay() + 6) % 7))
  return date
}

export const findPreviousSunday = (date: Date): Date => {
  date.setDate(date.getDate() - date.getDay())
  return date
}

export const getPreviousSunday = (): Date => {
  const today = new Date() // Step 1: Get the current date
  const dayOfWeek = today.getDay() // Step 2: Find the current day of the week
  let daysToSubtract = dayOfWeek + 7 // Ensure it always goes to the previous week

  if (dayOfWeek === 0) {
    // If today is Sunday, adjust to get the previous Sunday
    daysToSubtract = 7
  }

  const previousSunday = new Date(today)
  previousSunday.setDate(today.getDate() - daysToSubtract) // Step 4: Adjust the date

  // Optional: To reset hours, minutes, seconds, and milliseconds to 0
  previousSunday.setHours(0, 0, 0, 0)

  return previousSunday
}

export const groupEventsByWeek = (
  events: TaskEvent[],
): Map<string, TaskEvent[]> => {
  const groups: Map<string, TaskEvent[]> = new Map()

  events.forEach((event) => {
    const monday = findPreviousMonday(convertToDate(event.createdAt))
    const sunday = new Date(monday)
    sunday.setDate(monday.getDate() + 6)
    sunday.setHours(23, 59, 59, 999) // optional: to set the end of the day

    const weekRange = `${monday.toISOString().split('T')[0]} to ${
      sunday.toISOString().split('T')[0]
    }`

    if (!groups.has(weekRange)) {
      groups.set(weekRange, [])
    }

    groups.get(weekRange)!.push(event)
  })

  return groups
}

export const getDifferenceInDays = (
  firstDate: Date,
  secondDate: Date,
): number => {
  const normalizedFirstDate = new Date(
    firstDate.getFullYear(),
    firstDate.getMonth(),
    firstDate.getDate(),
  )
  const normalizedSecondDate = new Date(
    secondDate.getFullYear(),
    secondDate.getMonth(),
    secondDate.getDate(),
  )

  const differenceInMilliseconds =
    normalizedFirstDate.getTime() - normalizedSecondDate.getTime()

  // Convert milliseconds to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

  return Math.round(differenceInDays)
}

import { useRef, useState } from 'react'
import { Button, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { CustomModal } from '../../../components/CustomModal'
import LeftButtonTitleRightButton from '../../../components/LeftButtonTitleRightButton'
import ProgressSpinner from '../../../components/ProgressSpinner'
import StyledContainer from '../../../components/StyledContainer'
import InformationModal from '../../../components/info/InformationModal'
import VerifyTaskInfo from '../../../components/info/VerifyTaskInfo'
import { UserTaskStatus } from '../../../components/model/model'
import { useGoal } from '../../../context/GoalContext'
import { usePreviousLocation } from '../../../context/PreviousLocationContextType'
import { useTask } from '../../../context/TaskContext'
import { useUserCredentials } from '../../../context/UserCredentialsContext'
import {
  useGoalService,
  useTaskService,
  useUserTaskService,
} from '../../../service/ServicesHooks'
import {
  PostTaskRequest,
  UpdateTaskRequest,
} from '../../../service/TaskService'
import SecondUserTaskInfoBox from '../../user-tasks/SecondUserTaskInfoBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons'
import AddEventToCalendar from '../../../components/AddEventToCalendar'
import { useUserTasksContext } from '../../../context/UserTaskListContext'

enum ActionType {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
}

const ReviewAndSaveTaskPage = () => {
  const { t } = useTranslation('ReviewAndSaveTaskPage')
  const { taskInContext } = useTask()
  const { goalInContext } = useGoal()
  const { prevLocation } = usePreviousLocation()
  const { userCredentialsInContext } = useUserCredentials()
  const { addUserTask, updateUserTask, clearUserTasks } = useUserTasksContext()

  const [showInfoModal, setShowInfoModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [showProgressSpinner, setShowProgressSpinner] = useState<boolean>(false)
  const [showAddToCalendar, setShowAddToCalendar] = useState(false)

  const previousLocation = useRef<string>(prevLocation || '')
  const taskService = useTaskService()
  const userTaskService = useUserTaskService()
  const goalService = useGoalService()
  const navigate = useNavigate()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  if (!userCredentialsInContext || !userCredentialsInContext.userInfo?.userId) {
    throw new Error('userId is undefined')
  }

  const handleShowInfoModal = () => setShowInfoModal(true)
  const handleCloseCloseInfoModal = () => setShowInfoModal(false)

  const getFontSize = () => {
    return isMobile ? '1.3rem' : '1.8rem'
  }

  const handleError = (message: string) => {
    setErrorMessage(message)
    setShowErrorModal(true)
  }

  const connectTaskToGoal = async (taskId: string) => {
    const goalId = goalInContext?.id
    if (!goalId) {
      handleError(`Målet saknar id, försök igen senare.`)
      console.error('GoalId is undefined')
      return
    }
    const goalResponse = await goalService.addTaskToGoal({
      goalId: goalId,
      taskId: taskId,
    })

    if (goalResponse.status === 'FAILURE') {
      handleError(
        `Något gick fel när uppgiften skulle läggas till målet, försök igen senare.`,
      )
      console.error('Failed to add task to goal')
      return
    }
  }

  const saveUserTask = async () => {
    if (!taskInContext) {
      return { status: 'FAILURE', taskId: '' }
    }
    const postTaskRequest: PostTaskRequest = {
      task: taskInContext,
      email: userCredentialsInContext.userInfo?.email || '',
      username: userCredentialsInContext.userInfo?.email, //TODO: Change to username
    }

    const response = await taskService.postTask(postTaskRequest)

    if (response.status === 'SUCCESS') {
      await fetchUserTaskAndUpdateUserTasksContext(
        response.taskId,
        ActionType.ADD,
      )
    } else {
      console.error('Failed to post task')

      handleError(
        `Något gick fel när uppgiften skulle sparas, försök igen senare.`,
      )
    }
    return response
  }

  const updateTask = async () => {
    if (!taskInContext) {
      handleError('Uppgiften saknas, försök igen senare.')
      console.error('Task is missing')
      return
    }
    const request: UpdateTaskRequest = {
      id: taskInContext.id,
      name: taskInContext.name,
      htmlDescription: taskInContext.htmlDescription,
      category: taskInContext.category,
      endDate: taskInContext.endDate,
      organizationId: taskInContext.organizationId,
      userId: taskInContext.userId,
      status: taskInContext.status,
    }

    const updateResponse = await taskService.updateTask(request)

    if (updateResponse.status === 'SUCCESS') {
      await fetchUserTaskAndUpdateUserTasksContext(
        taskInContext.id,
        ActionType.UPDATE,
      )
      console.log(`Task updated, taskId=${taskInContext.id}`)
      navigate(`/usertasks/list`)
    } else {
      handleError(
        `Något gick fel när uppgiften skulle uppdateras, försök igen senare.`,
      )
      console.error('Failed to update task')
      return
    }
  }

  //TODO: this function is duplicated in AddQuestionAndAnswerPage
  const fetchUserTaskAndUpdateUserTasksContext = async (
    taskId: string,
    action: ActionType,
  ) => {
    //Get user task by userId and taskId (there should be only one, and add it to the context)
    const userTasksResponse =
      await userTaskService.getUserTaskByUserIdAndTaskId({
        userId: userCredentialsInContext.userInfo!.userId,
        taskId: taskId,
      })

    if (userTasksResponse.status !== 'SUCCESS') {
      console.log(
        'Failed to fetch user task, clear context so it will be reloaded later on.',
      )
      clearUserTasks()
    } else {
      switch (action) {
        case ActionType.ADD:
          addUserTask(userTasksResponse.userTask!)
          break
        case ActionType.UPDATE:
          updateUserTask(userTasksResponse.userTask!)
          break
        default:
          console.error('Missing ActionType', action)
      }
    }
  }

  const handleClose = () => {
    setShowErrorModal(false)
    setErrorMessage('')
  }

  const hasGoalInContext = goalInContext !== null

  //Main function for this page
  const saveOrUpdateTask = async () => {
    try {
      setShowProgressSpinner(true)
      const isFromEditPage = previousLocation.current.endsWith('edit')
      const isFromAddNewTaskPage = previousLocation.current.endsWith('add')

      if (isFromAddNewTaskPage && hasGoalInContext) {
        const response = await saveUserTask()

        await connectTaskToGoal(response.taskId)
        setShowProgressSpinner(false)
        navigate(`/goals/open`)
        return
      }

      if (isFromAddNewTaskPage && !hasGoalInContext) {
        await saveUserTask()
        setShowProgressSpinner(false)
        navigate(`/usertasks/list`)
        return
      }

      if (isFromEditPage) {
        await updateTask()
        if (hasGoalInContext) {
          navigate(`/goals/open`)
          return
        }
        setShowProgressSpinner(false)
        navigate(`/usertasks/list`)
        return
      }

      //Try to save task anyway and go back to user tasks

      console.log(
        'Seems like previous location is unknown, save task anyway',
        previousLocation.current,
      )
      await saveUserTask()
      setShowProgressSpinner(false)
      navigate(`/usertasks/list`)
      return
    } catch (err) {
      handleError(`Något gick fel, kunde inte spara uppgiften.`)
      return
    }
  }

  if (showProgressSpinner) {
    return <ProgressSpinner />
  }

  const handleCloseAddToCalendar = () =>
    setShowAddToCalendar(!showAddToCalendar)

  return (
    <>
      <div className={'mt-4'}></div>

      <StyledContainer>
        <LeftButtonTitleRightButton
          title={t('title')}
          titleFontSize={getFontSize()}
          leftButtonTitle={t('back')}
          onLeftButtonClick={() => navigate(-1)}
          rightButtonTitle={t('info')}
          onRightButtonClick={handleShowInfoModal}
        />
        {goalInContext && (
          <p className={'text-center'}>Mål: {goalInContext.name}</p>
        )}
      </StyledContainer>

      <div className={'mt-4'}></div>
      <SecondUserTaskInfoBox
        name={taskInContext!.name}
        category={taskInContext!.category}
        status={UserTaskStatus.New}
        endDate={taskInContext!.endDate}
        timesDone={0}
        htmlDescription={taskInContext!.htmlDescription}
        taskDataType={taskInContext!.dataType}
      />

      <Container>
        {!isMobile && (
          <Row>
            <Button
              onClick={() => setShowAddToCalendar(!showAddToCalendar)}
              variant={'secondary'}
              className="mb-3 me-md-4 me-4 w-30"
              style={{ maxHeight: '40px' }}
            >
              <span>
                <FontAwesomeIcon icon={faCalendarPlus} size={'1x'} />{' '}
                {t('addReminderToCalendar')}
              </span>
            </Button>
          </Row>
        )}
        <Row>
          <Button
            onClick={saveOrUpdateTask}
            className={'mt-4 w-100'}
            variant="success"
          >
            {t('saveButton')}
          </Button>
        </Row>
      </Container>

      {showAddToCalendar && taskInContext && (
        <AddEventToCalendar
          eventTitle={taskInContext.name}
          description={
            taskInContext.htmlDescription ? taskInContext.htmlDescription : ''
          }
          endDate={taskInContext.endDate ? taskInContext.endDate : null}
          showAddEventToCalendarButton={showAddToCalendar}
          handleCloseAddTaskToCal={handleCloseAddToCalendar}
        />
      )}

      <InformationModal
        title={'Granska uppgift'}
        children={<VerifyTaskInfo />}
        showInfoModal={showInfoModal}
        handleCloseInfoModal={handleCloseCloseInfoModal}
      />

      <div className={'mb-4'}></div>
      <CustomModal
        show={showErrorModal}
        title="Något gick fel när du skulle lägga till uppgift"
        message={errorMessage}
        handleClose={handleClose}
        type={'ERROR'}
      />
    </>
  )
}

export default ReviewAndSaveTaskPage

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CustomModal } from '../../../components/CustomModal'
import LeftButtonTitleRightButton from '../../../components/LeftButtonTitleRightButton'
import ProgressSpinner from '../../../components/ProgressSpinner'
import StyledContainer from '../../../components/StyledContainer'
import AddQuestionsAndAnswersInfo from '../../../components/info/AddQuestionsAndAnswersInfo'
import InformationModal from '../../../components/info/InformationModal'
import { MultipleAnswersData, Task } from '../../../components/model/model'
import { useGoal } from '../../../context/GoalContext'
import { useTask } from '../../../context/TaskContext'
import { useUserCredentials } from '../../../context/UserCredentialsContext'
import { useUserTasksContext } from '../../../context/UserTaskListContext'
import {
  useGoalService,
  useTaskService,
  useUserTaskService,
} from '../../../service/ServicesHooks'

enum ActionType {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
}

const AddQuestionAndMultipleAnswersPage = () => {
  const { t } = useTranslation('AddQuestionAndMultipleAnswersPage')
  const [task, setTask] = useState<Task>({} as Task)
  const [item, setItem] = useState<MultipleAnswersData>({
    question: '',
    answer: '',
    alternative1: '',
    alternative2: '',
  })
  const [items, setItems] = useState<MultipleAnswersData[]>(
    [] as MultipleAnswersData[],
  )
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showProgressSpinner, setShowProgressSpinner] = useState<boolean>(false)

  const { taskInContext } = useTask()
  const { goalInContext } = useGoal()
  const { updateUserTask, addUserTask, clearUserTasks } = useUserTasksContext()
  const { userCredentialsInContext } = useUserCredentials()

  const questionInputRef = useRef<HTMLInputElement>(null)
  const taskService = useTaskService()
  const goalService = useGoalService()
  const userTaskService = useUserTaskService()
  const navigate = useNavigate()

  if (
    userCredentialsInContext?.userInfo?.userId === undefined ||
    userCredentialsInContext?.userInfo?.organizationId === undefined
  ) {
    throw new Error('No user credentials')
  }

  useEffect(() => {
    const getTask = async () => {
      try {
        if (!taskInContext) {
          return
        }

        setTask(taskInContext)

        console.log('taskInContext in add question multiple', taskInContext)

        if (!taskInContext.data || taskInContext.data === '') {
          console.log('No data')
          setItems([])
          return
        }

        const data = JSON.parse(taskInContext.data) as MultipleAnswersData[]

        setItems(data)
      } catch (error) {
        console.log('error', error)
        if (error instanceof Error) {
          handleError(error)
        }
      }
    }

    getTask()
  }, [taskInContext])

  const saveOrUpdateTask = async () => {
    if (task.id === undefined || task.id === '') {
      setShowProgressSpinner(true)
      task.data = JSON.stringify(items)
      const createNewTaskResponse = await taskService.postTask({
        task: task,
        email: userCredentialsInContext.userInfo!.email,
        username: userCredentialsInContext.userInfo!.email,
      })
      if (createNewTaskResponse.status === 'FAILURE') {
        console.error('Failed to create new task')
        return
      }

      await fetchUserTaskAndUpdateUserTasksContext(
        createNewTaskResponse.taskId,
        ActionType.ADD,
      )

      if (goalInContext) {
        await connectTaskToGoal(createNewTaskResponse.taskId)
        setShowProgressSpinner(false)
      }
    } else {
      const updateTaskResponse = await taskService.updateTask({
        id: task.id,
        name: task.name,
        category: task.category,
        htmlDescription: task.htmlDescription,
        endDate: task.endDate,
        organizationId: userCredentialsInContext.userInfo!.organizationId,
        userId: userCredentialsInContext.userInfo!.userId,
        data: items,
        status: task.status,
      })

      if (updateTaskResponse.status != 'SUCCESS') {
        console.error(`Failed to update task ${task.id}`)
        handleError(new Error('Misslyckes att uppdatera uppgiften'))
        return
      }

      await fetchUserTaskAndUpdateUserTasksContext(task.id, ActionType.UPDATE)
    }
  }

  const handleSaveOrUpdate = async () => {
    await saveOrUpdateTask()
    if (goalInContext) {
      navigate(`/goals/open`)
    } else {
      navigate(`/usertasks/list`)
    }
  }

  //TODO: this function is duplicated in ReviewAndSaveTaskPage as well. Maybe the context itself should do this update?
  const fetchUserTaskAndUpdateUserTasksContext = async (
    taskId: string,
    action: ActionType,
  ) => {
    //Get user task by userId and taskId (there should be only one, and add it to the context)
    const userTasksResponse =
      await userTaskService.getUserTaskByUserIdAndTaskId({
        userId: userCredentialsInContext.userInfo!.userId,
        taskId: taskId,
      })

    console.log(userTasksResponse)

    if (userTasksResponse.status !== 'SUCCESS') {
      console.log(
        'Failed to fetch user task, clear context so it will be reloaded later on.',
      )
      clearUserTasks()
    } else {
      console.log('add or update userTask in context', action)
      switch (action) {
        case ActionType.ADD:
          addUserTask(userTasksResponse.userTask!)
          break
        case ActionType.UPDATE:
          updateUserTask(userTasksResponse.userTask!)
          break
        default:
          console.error('Missing ActionType', action)
      }
    }
  }

  const connectTaskToGoal = async (taskId: string) => {
    const goalId = goalInContext?.id
    if (!goalId) {
      handleError(new Error(`Målet saknar id, försök igen senare.`))
      console.error('GoalId is undefined')
      return
    }
    const goalResponse = await goalService.addTaskToGoal({
      goalId: goalId,
      taskId: taskId,
    })

    if (goalResponse.status === 'FAILURE') {
      handleError(
        new Error(
          `Något gick fel när uppgiften skulle läggas till målet, försök igen senare.`,
        ),
      )
      console.error('Failed to add task to goal')
      return
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()

      if (item.answer === '' || item.question === '') {
        handleError(new Error('Fråga och svar måste fyllas i'))
        return
      }

      const data: MultipleAnswersData[] = [...items, item]

      setItems(data)

      setItem({
        question: '',
        answer: '',
        alternative1: '',
        alternative2: '',
      })

      if (questionInputRef && questionInputRef.current) {
        questionInputRef.current.focus()
      }
    } catch (error) {
      console.log('error', error)
      if (error instanceof Error) {
        handleError(error)
      }
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItem({
      ...item,
      [event.target.name]: event.target.value,
    })
  }

  const handleError = (error: Error) => {
    setErrorMessage(error.message)
    setShowErrorModal(true)
  }

  const handleClose = () => {
    setShowErrorModal(false)
    setErrorMessage('')
  }

  const handleRemove = async (idx: number) => {
    try {
      console.log('handleRemove', idx)

      const questions = [...items]
      questions.splice(idx, 1)

      setItems(questions)
    } catch (error) {
      console.log('error', error)
      if (error instanceof Error) {
        handleError(error)
      }
    }
  }

  const handleShowInfoModal = () => setShowInfoModal(true)
  const handleCloseCloseInfoModal = () => setShowInfoModal(false)

  if (showProgressSpinner) {
    return <ProgressSpinner />
  }

  return (
    <>
      <div className={'mt-4'}></div>

      <StyledContainer useTaskCardBg={true}>
        <LeftButtonTitleRightButton
          title={t('title')}
          leftButtonTitle={t('backButton')}
          rightButtonTitle={t('infoButton')}
          onLeftButtonClick={() => navigate(-1)}
          onRightButtonClick={handleShowInfoModal}
        />

        <div className="text-center">
          <p>
            {t('taskLabel')} {task.name}
          </p>
        </div>

        <Container className="my-4">
          <Row className="justify-content-md-center">
            <Col md={12}>
              <Form onSubmit={handleSubmit}>
                <Form.Group as={Col} md="12">
                  <Form.Label>{t('questionLabel')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="question"
                    autoFocus
                    ref={questionInputRef}
                    value={item.question}
                    placeholder={t('questionPlaceholder')}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} md="12" className="mt-2">
                  <Form.Label>{t('answerLabel')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="answer"
                    value={item.answer}
                    placeholder={t('answerPlaceholder')}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} md="12" className="mt-2">
                  <Form.Label>{t('alternative1Label')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="alternative1"
                    value={item.alternative1}
                    placeholder={t('alternative1Placeholder')}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} md="12" className="mt-2">
                  <Form.Label>{t('alternative2Label')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="alternative2"
                    value={item.alternative2}
                    placeholder={t('alternative2Placeholder')}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} md="12">
                  <Button
                    className={'mt-4 w-100'}
                    variant="secondary"
                    type="submit"
                  >
                    {t('addButton')}
                  </Button>
                </Form.Group>

                <CustomModal
                  show={showErrorModal}
                  title={t('errorModalMessage')}
                  message={errorMessage}
                  handleClose={handleClose}
                  type={'ERROR'}
                />
              </Form>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={12}>
              {items.length > 0 && (
                <Table
                  className="custom-table custom-table-striped table-striped mt-4"
                  responsive="sm"
                  hover
                >
                  <thead>
                    <tr>
                      <th className="hide">{t('fields.id')}</th>
                      <th>{t('fields.question')}</th>
                      <th>{t('fields.answer')}</th>
                      <th>{t('fields.delete')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((qAndA, idx) => (
                      <tr key={idx}>
                        <td>{qAndA.question}</td>
                        <td>{qAndA.answer} </td>
                        <td>
                          <FontAwesomeIcon
                            className="cursorPointer"
                            onClick={() => handleRemove(idx)}
                            icon={faTrash}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
          <Form.Group as={Col} md="12">
            <Button
              className={'mt-4 w-100'}
              variant="success"
              type="button"
              onClick={handleSaveOrUpdate}
            >
              {t('saveButton')}
            </Button>
          </Form.Group>
        </Container>

        <InformationModal
          title={t('addQuestionAndAnswerInfo')}
          children={<AddQuestionsAndAnswersInfo />}
          showInfoModal={showInfoModal}
          handleCloseInfoModal={handleCloseCloseInfoModal}
        />
      </StyledContainer>
      <div className={'mb-4'}></div>
    </>
  )
}

export default AddQuestionAndMultipleAnswersPage

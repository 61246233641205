import { SyntheticEvent, useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuthService } from '../../service/ServicesHooks'
import { isValidEmail } from '../../utils/isValidEmail'
import ProgressSpinner from '../ProgressSpinner'
import StyledContainer from '../StyledContainer'
import ResetPasswordComponent from './PasswordReset'
import ResetConfirmationComponent from './PasswordResetConfirmation'
import SignUpComponent from './SignUpComponent'
import ConfirmSignupComponent from './SignUpConfirmation'

interface LoginProps {
  setIsSignedInCallback: (isSignedIn: boolean) => void
}

const LoginComponent = ({ setIsSignedInCallback }: LoginProps) => {
  const { t } = useTranslation('LoginComponent')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showSignUp, setShowSignUp] = useState(false)
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [showResetConfirmation, setShowResetConfirmation] = useState(false)
  const [showConfirmSignUp, setShowConfirmSignUp] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const authService = useAuthService()

  const { loginEmail } = useParams()
  const { rerenderKey } = useParams()

  useEffect(() => {
    if (loginEmail) {
      setEmail(loginEmail)
    }

    if (rerenderKey) {
      setShowConfirmSignUp(false)
      setShowSignUp(false)
      setErrorMessage('')
      setPassword('')
    }
  }, [loginEmail, rerenderKey])

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()

    setIsLoading(true)

    console.log('Signing in with email:', email)

    try {
      if (!isValidEmail(email)) {
        setErrorMessage(t('invalidEmail', { email: email }))
        setIsLoading(false)
        return
      }
      if (email && password) {
        console.log('Signing in with email:', email)
        await authService.signOut()
        const signInOutput = await authService.login(email, password)

        console.log('signInOutput:', signInOutput)

        if (signInOutput.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
          console.log('User is registred, but not confirmed')
          setShowConfirmSignUp(true)
          setIsLoading(false)
          return
        }

        setIsSignedInCallback(signInOutput.isSignedIn)
        setIsLoading(false)

        const redirectLink = localStorage.getItem('redirectAfterLogin')
        if (redirectLink) {
          localStorage.removeItem('redirectAfterLogin')
          console.log('Redirecting to:', redirectLink)
          navigate(redirectLink)
        } else {
          navigate('/')
        }
      }
    } catch (error) {
      console.error(error)

      if (error instanceof Error) {
        if (error.name === 'UserAlreadyAuthenticatedException') {
          console.warn(
            'User is already authenticated, should not be able to login again, but sign out and retry to login',
          )
          await authService.signOut()
          navigate('/login')
          return
        }
      }
      setIsLoading(false)
      setErrorMessage(t('invalidEmailOrPassword'))
    }
  }

  if (isLoading) {
    return <ProgressSpinner />
  }

  return (
    <>
      {/* /* LOGIN */}
      <div className="my-3"></div>
      <StyledContainer useTaskCardBg={true}>
        {!showSignUp && !showPasswordReset && !showConfirmSignUp && (
          <Container className="mt-2 mb-4">
            <Row className="justify-content-md-center">
              <Col md={12}>
                <div className="goachy-header-title  text-center mt-2">
                  {t('title')}
                </div>
                <Form onSubmit={handleSubmit}>
                  {errorMessage && (
                    <Alert variant="danger" style={{ fontSize: '1.1rem' }}>
                      {errorMessage}
                    </Alert>
                  )}
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{t('emailLabel')}</Form.Label>
                    <Form.Control
                      id="formBasicEmail"
                      type="email"
                      name="username"
                      placeholder={t('emailPlaceholder')}
                      value={email}
                      autoFocus
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="username"
                      required
                    />
                  </Form.Group>

                  <Form.Group className={'my-4'} controlId="formBasicPassword">
                    <Form.Label>{t('passwordLabel')}</Form.Label>
                    <Form.Control
                      id="formBasicPassword"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder={t('passwordPlaceholder')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                      required
                    />
                    <Form.Check
                      className="mt-2"
                      type="checkbox"
                      label="Show Password"
                      onChange={() => setShowPassword(!showPassword)}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-3 w-100"
                  >
                    {t('loginButton')}
                  </Button>
                  <div className="text-center mt-3">
                    <a
                      onClick={() => setShowPasswordReset(!showPasswordReset)}
                      href="#forgotPassword"
                    >
                      {t('forgotPassword')}
                    </a>
                  </div>
                  <div className="loginSignUpSeparator ">
                    <span className="taskGoalCardBg">{t('orSeparator')}</span>
                  </div>
                  <Button
                    onClick={() => setShowSignUp(!showSignUp)}
                    variant="primary"
                    className="mt-3 w-100"
                  >
                    {t('createAccountButton')}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        )}

        {/* SIGNUP */}
        {showSignUp && (
          <SignUpComponent
            authService={authService}
            showConfirmModal={showConfirmSignUp}
          />
        )}

        {showConfirmSignUp && (
          <ConfirmSignupComponent email={email} authService={authService} />
        )}

        {/* PASSWORD RESET */}

        {showPasswordReset && !showResetConfirmation && (
          <ResetPasswordComponent
            authService={authService}
            setShowResetPasswordCb={setShowResetConfirmation}
            setEmailReceipientCb={setEmail}
          />
        )}

        {showResetConfirmation && (
          <ResetConfirmationComponent
            authService={authService}
            email={email}
            setShowResetConfirmationCb={setShowResetConfirmation}
            setShowPasswordResetCb={setShowPasswordReset}
          />
        )}
      </StyledContainer>
      <div className="my-3"></div>
    </>
  )
}

export default LoginComponent

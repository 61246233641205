import { Organization, UserTask } from '../components/model/model'
import { AuthService } from './AuthService'

const goachyApiUrl = import.meta.env.VITE_CHALLENGE_API_URL

export type OrganizationResponse = {
  organizations: Organization[]
  status: 'SUCCESS' | 'FAILURE'
  message?: string
  userTasks?: UserTask[]
}

export type GetOrganizationTasksResponse = {
  //TODO: Change to OrganizationTasks
  orgTasks: UserTask[]
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export type GetUserTaskForOrganizationResponse = {
  userTasks: UserTask[]
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export class OrganizationService {
  private authService: AuthService

  constructor(authService: AuthService) {
    this.authService = authService
  }

  //TODO: move this to OrganizationTaskService
  public async getTasksOwnedByOrganization(
    organizationId: string,
  ): Promise<GetOrganizationTasksResponse> {
    try {
      const jwtToken = await this.getToken()

      if (!organizationId) {
        return {
          orgTasks: [],
          status: 'FAILURE',
          message: 'Missing organizationId',
        }
      }

      if (!goachyApiUrl) {
        throw new Error('Missing goachyApiUrl')
      }

      const response = await fetch(
        `${goachyApiUrl}organizations/${organizationId}/tasks`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )

      const orgTasks = (await response.json()) as UserTask[]

      return {
        orgTasks: orgTasks,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get tasks owned by organization, error:', error)
      return {
        orgTasks: [],
        status: 'FAILURE',
        message: 'Failed to get tasks owned by organization',
      }
    }
  }

  //TODO: move this to OrganizationTaskService
  public async getUserTasksRelatedToOrganization(
    organizationId: string,
  ): Promise<GetUserTaskForOrganizationResponse> {
    try {
      const jwtToken = await this.getToken()

      if (!organizationId) {
        return {
          userTasks: [],
          status: 'FAILURE',
          message: 'Missing organizationId',
        }
      }

      if (!goachyApiUrl) {
        throw new Error('Missing goachyApiUrl')
      }

      const response = await fetch(
        `${goachyApiUrl}organizations/${organizationId}/usertasks`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )

      const userTasks = (await response.json()) as UserTask[]

      return {
        userTasks: userTasks,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get tasks owned by organization, error:', error)
      return {
        userTasks: [],
        status: 'FAILURE',
        message: 'Failed to get tasks owned by organization',
      }
    }
  }

  public async getOrganizations(): Promise<OrganizationResponse> {
    try {
      const jwtToken = await this.getToken()

      if (!goachyApiUrl) {
        throw new Error('Missing goachyApiUrl')
      }

      const response = await fetch(`${goachyApiUrl}organizations`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      const organizations = (await response.json()) as Organization[]

      return {
        organizations,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get organizations, error:', error)
      return {
        organizations: [],
        status: 'FAILURE',
        message: 'Failed to get organizations',
      }
    }
  }

  public async getOrganization(
    organizationId: string,
  ): Promise<OrganizationResponse> {
    try {
      const jwtToken = await this.getToken()

      if (!goachyApiUrl) {
        throw new Error('Missing goachyApiUrl')
      }

      const response = await fetch(
        `${goachyApiUrl}organizations/${organizationId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )

      const organization = (await response.json()) as Organization

      return {
        organizations: [organization],
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get organization, error:', error)
      return {
        organizations: [],
        status: 'FAILURE',
        message: 'Failed to get organization',
      }
    }
  }

  public async updateOrganization(
    organization: Organization,
  ): Promise<OrganizationResponse> {
    try {
      const jwtToken = await this.getToken()

      await fetch(`${goachyApiUrl}organizations/${organization.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
        body: JSON.stringify(organization),
      })
      return {
        organizations: [],
        status: 'SUCCESS',
        message: 'Organization updated',
      }
    } catch (error) {
      console.error('Failed to update organization, error:', error)
      return {
        organizations: [],
        status: 'FAILURE',
        message: 'Failed to update organization',
      }
    }
  }

  public async addOrganization(
    organization: Organization,
  ): Promise<OrganizationResponse> {
    try {
      const jwtToken = await this.getToken()

      await fetch(`${goachyApiUrl}organizations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
        body: JSON.stringify(organization),
      })
      return {
        organizations: [],
        status: 'SUCCESS',
        message: 'Organization added',
      }
    } catch (error) {
      console.error('Failed to add organization, error:', error)
      return {
        organizations: [],
        status: 'FAILURE',
        message: 'Failed to add organization',
      }
    }
  }

  private async getToken() {
    const jwtToken = await this.authService.getJwtToken()

    if (!jwtToken) {
      throw new Error('Missing jwtToken')
    }
    return jwtToken
  }
}

import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { Task, TaskDataType } from '../../components/model/model'
import './SharedOrPublicTaskForm.css'
import { UserCredentials } from '../../App'
import { Button, Col, Container, Row } from 'react-bootstrap'

export interface SharedOrPublicTaskFormProps {
  task: Task
  isLoggedIn: boolean
  userCredentials: UserCredentials | null
  sharedOrPublicTask: 'SHARED' | 'PUBLIC'
}
const SharedOrPublicTaskForm = ({
  task,
  isLoggedIn,
  userCredentials,
  sharedOrPublicTask,
}: SharedOrPublicTaskFormProps) => {
  const { t } = useTranslation('SharedOrPublicTaskForm')

  const navigate = useNavigate()

  const emailFirstPart = isLoggedIn
    ? userCredentials?.userInfo?.email.split('@')[0]
    : ''

  const isOneTaskOrRecurringTask =
    task.dataType === TaskDataType.OneTimeTaskDataType ||
    task.dataType === TaskDataType.RecurringTaskDataType

  return (
    <>
      <div className={`g-task-info-item mt-2`}>
        <span className="g-task-info-item_link">
          {sharedOrPublicTask === 'SHARED' && (
            <>
              {isLoggedIn ? (
                <>
                  <p className="text-center">
                    {t('sharedTaskLoggedInInvitationText', {
                      taskName: task.name,
                      userName: emailFirstPart,
                    })}
                  </p>
                </>
              ) : (
                <>
                  <p className="text-center">
                    {t('sharedTaskNotLoggedInInvitationText1', {
                      taskName: task.name,
                    })}
                  </p>
                </>
              )}
            </>
          )}

          {sharedOrPublicTask === 'PUBLIC' && (
            <>
              {isLoggedIn ? (
                <>
                  <p className="text-center">
                    {t('publicTaskLoggedInInvitationText', {
                      taskName: task.name,
                      userName: emailFirstPart,
                    })}
                  </p>
                </>
              ) : (
                <>
                  <p className="text-center">
                    {t('publicTaskNotLoggedInInvitationText1', {
                      taskName: task.name,
                    })}
                  </p>
                </>
              )}
            </>
          )}

          <h3 className="text-center mt-4">{task.name!}</h3>

          {task.htmlDescription && (
            <>
              <div
                className={'task-text text-center'}
                dangerouslySetInnerHTML={{ __html: task.htmlDescription }}
              />
              {task.category && (
                <div className="text-center">
                  {t('category')}: {task.category}
                </div>
              )}
              {task.endDate && (
                <div className="text-center">
                  {t('endDate')}: {task.endDate}
                </div>
              )}
            </>
          )}

          {isOneTaskOrRecurringTask && (
            <Container>
              <Row className="justify-content-center align-items-center">
                {isLoggedIn && (
                  <Col xs="auto">
                    <div
                      onClick={() =>
                        navigate(`/usertasks/${task.id}`, {
                          state: { task: task },
                        })
                      }
                      className="flex-item"
                      style={{ width: '250px', padding: '10px' }}
                    >
                      {t('openTask')}
                    </div>
                  </Col>
                )}
                {!isLoggedIn && (
                  <>
                    <Col xs="auto" className="text-center mt-4">
                      <p>{t('sharedTaskNotLoggedInInvitationText2')}</p>
                    </Col>
                    <Col xs="auto">
                      <Button
                        onClick={() => {
                          localStorage.setItem(
                            'redirectAfterLogin',
                            `/public/usertasks/${task.id}`,
                          )
                          navigate('/login/user/123')
                        }}
                        className="mb-3 me-md-2 me-2 w-30"
                      >
                        {t('showLoginToStartTask')}
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          )}

          {/* MULTIPLE CHOICE */}
          {TaskDataType.MultipleChoiceDataType === task.dataType && (
            <>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '100%' }}
              >
                <div
                  onClick={() =>
                    navigate(`/usertasks/multiple-answers/${task.id}`, {
                      state: {
                        task: task,
                        withPoints: true,
                      },
                    })
                  }
                  className="flex-item"
                  style={{ width: '250px', padding: '10px' }}
                >
                  {t('openTask')}
                </div>
              </div>
            </>
          )}

          {/* QUESTIONS AND ANSWERS */}
          {task.dataType === TaskDataType.QuestionAndAnswerDataType && (
            <div className="flex-container">
              <div
                onClick={() =>
                  navigate(`/usertasks/written-answer/${task.id}`, {
                    state: {
                      task: task,
                      withPoints: true,
                      withMisspellings: true,
                    },
                  })
                }
                className="flex-item"
                style={{ width: '250px', padding: '10px' }}
              >
                {t('writeVocabulary')}
              </div>
              <div
                onClick={() =>
                  navigate(`/usertasks/written-answer/${task.id}`, {
                    state: { task: task },
                  })
                }
                className="flex-item"
                style={{ width: '250px', padding: '10px' }}
              >
                {t('writeCorrectAnswer')}
              </div>

              <div
                onClick={() =>
                  navigate(`/usertasks/flashcard/${task.id}`, {
                    state: { task: task },
                  })
                }
                className="flex-item"
                style={{ width: '250px', padding: '10px' }}
              >
                {t('flashCards')}
              </div>
              <div
                className="flex-item"
                style={{ width: '250px', padding: '10px' }}
                onClick={() =>
                  navigate(`/usertasks/q-and-q-show-all/${task.id}`, {
                    state: { task: task },
                  })
                }
              >
                {t('questionsAndAnswers')}
              </div>
            </div>
          )}
        </span>
      </div>
    </>
  )
}

export default SharedOrPublicTaskForm

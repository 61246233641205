import { GoachyRole } from '../../Roles'

export type UserId = string
export type TaskId = string
export type OrganizationId = string

export interface Task {
  id: string
  name: string
  category: string
  htmlDescription?: string
  data: string
  dataType: TaskDataType
  userId: string
  organizationId: string
  endDate?: string
  status: TaskStatus
}

export enum TaskStatus {
  Private = 'Private',
  Public = 'Public',
  Deleted = 'Deleted',
}

export enum TaskDataType {
  OneTimeTaskDataType = 'OneTimeTaskDataType',
  RecurringTaskDataType = 'RecurringTaskDataType',
  QuestionAndAnswerDataType = 'QuestionAndAnswerDataType',
  MultipleChoiceDataType = 'MultipleChoiceDataType',
  TrueFalseDataType = 'TrueFalseDataType',
}

export interface TaskData {}

/**
 * This type can be used for different types of tasks, for example:
 * - Fill in the blank
 * - Write answer
 * - Flash card
 */
export interface QuestionAndAnswerData extends TaskData {
  question: string
  answer: string
  correctAnswerDescription?: string
  incorrectAnswerDescription?: string
}

export interface MultipleAnswersData extends TaskData {
  question: string
  answer: string
  alternative1: string
  alternative2: string
}
// export interface MultipleChoiceData extends TaskData {
//   question: string
//   answer: string
//   options: string[]
//   correctAnswer: string
//   correctAnswerDescription: string
//   incorrectAnswerDescription: string
// }

export interface TrueFalseData extends TaskData {
  question: string
  answer: boolean
  correctAnswerDescription: string
  incorrectAnswerDescription: string
}

export interface TaskEvent {
  id: string
  eventTaskId: string
  eventType: TaskEventType
  comment?: string
  result?: number
  timeInSec?: string
  status: UserTaskStatus
  createdAt: string
}

export interface Organization {
  id: string
  email: string
  name: string
  orgNumber?: string
  address?: string
  city?: string
  state?: string
  postalCode?: string
  country?: string
  phone?: string
  website?: string
  logo?: string
}

export interface UserTask {
  id: string
  taskId: string
  taskName: string
  taskCategory: string
  typeOfRelation: TypeOfRelation
  endDate?: string
  assignedBy?: string
  assignedTo?: string
  status: UserTaskStatus
  timesDone: number
  eventCount: number
  dataType: TaskDataType
}

export interface OrgTask {
  id: string
  taskId: string
  taskName: string
  taskCategory: string
  typeOfRelation: TypeOfRelation
  endDate?: string
  email: string
  username: string
  timesDone: number
  eventCount: number
}

export interface User {
  email: string
  organizationId: string
  displayName?: string
  id?: string
  firstName?: string
  lastName?: string
  team?: string
  roles: GoachyRole[]
}

export interface Goal {
  id: string
  name: string
  description: string
  author: string
  goalType: GoalType
  targetTaskCountPerWeek?: number
  targetResult?: number
  createdAt?: string
  category?: string
  endDate?: string
}

export interface GoalTask {
  goalId: string
  taskId: string
}

export enum GoalType {
  FinishAllTasks = 'FinishAllTasks',
  MeasurableGoal = 'MeasurableGoal',
}

export interface UserGoal {
  userId: string
  goalId: string
}

export enum TypeOfRelation {
  Assigned = 'ASSIGNED',
  Unassigned = 'UNASSIGNED',
  Removed = 'REMOVED',
  Owner = 'OWNER',
}

export enum UserTaskStatus {
  New = 'New',
  Ongoing = 'Ongoing',
  Done = 'Done',
  Deleted = 'Deleted',
  Hidden = 'Hidden',
}

export enum TaskEventType {
  New = 'New',
  Ongoing = 'Ongoing',
  Done = 'Done',
  Comment = 'Comment',
  TaskWorkCompleted = 'TaskWorkCompleted',
}
